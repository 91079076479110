import React from 'react';
import * as Icon from 'react-feather';
import { Link } from "gatsby";
import Track1 from "../../assets/images/cloudCampaign/Track1.png"
import Track2 from "../../assets/images/cloudCampaign/Track2.png"
import Track3 from "../../assets/images/cloudCampaign/Track3.png"
import Track4 from "../../assets/images/cloudCampaign/Track4.png"

const Features = () => {
    return (
        <div className="boxes-area">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-3 col-sm-6">
						<div className="single-box">
								<img src={Track1} alt="Cloud Computing Fundementals" width="50%" height="50%"/>
							<h3>
								<Link to="/badge1">
									Cloud Computing Fundamentals
								</Link>
							</h3>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6">
						<div className="single-box track2">
								<img src={Track2} alt="Cloud Computing Fundementals" width="50%" height="50%"/>
							<h3>
								<Link to="/badge2">
									Infrastructure in Google Cloud
								</Link>
							</h3>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6">
						<div className="single-box track3">							
								<img src={Track3} alt="Cloud Computing Fundementals" width="50%" height="50%"/>
							<h3>
								<Link to="/badge3">
									Networking and Security in Google Cloud
								</Link>
							</h3>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6">
						<div className="single-box track4">
								<img src={Track4} alt="Cloud Computing Fundementals" width="50%" height="50%"/>
							<h3>
								<Link to="/badge4">
									Data, ML, and AI in Google Cloud
								</Link>
							</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Features;
